<template>
  <section>
    <hero-global title="Voluntariado" last-width="40" first-width="60" subtitle="Voluntariado Social CERMI"
      breadcrumb="Voluntariado Social CERMI" img="/img/volunteering/volunteering_1.svg" />
  </section>
  <section class="m-top-2-rem m-bottom-2-rem container w-100 text-center m-auto">
    <article>
      <img src="/img/logo_voluntariado.jpg" alt="logo voluntariado" class="logo-voluntariado">
      <h2>Documento director y normas reguladoras de las actividades y funcionamiento interno del voluntariado en la
        Entidad CERMI</h2>
    </article>
  </section>
  <section class="container p-2-rem">
    <div class="grid-2 justify-content-between">
      <div class="grid w-60">
        <h2 class="subtitle">Introducción</h2>
        <article>
          <p>El Comité Español de Representantes de Personas con Discapacidad (CERMI) renueva con este documento su
            estrategia de voluntariado social para reforzar y dar más participación en las actividades de la plataforma
            global de representación de la discapacidad organizada a la dimensión altruista y solidaria de la
            ciudadanía.</p>
          <br />
          <p>A través de la aprobación de un documento rector sobre voluntariado inclusivo, adoptado en el mes de
            septiembre de 2022 por el Comité Ejecutivo de la entidad, el CERMI actualiza los términos de sus políticas
            de voluntariado, introduciendo al personal voluntario más intensamente en los procesos de toma de decisión y
            en la gobernanza de la plataforma.</p>
          <br />
          <p>El CERMI quiere aprovechar la pulsión solidaria que significa el voluntariado de modo innovador, ya que, al
            no ser una entidad de atención social directa, sino de representación e incidencia política el compromiso y
            el talento del personal voluntario colaborador debe reconducirse a acciones cualitativas de refuerzo de la
            agenda política.</p>
        </article>
      </div>
      <div class="grid w-38">
        <img class="d-block w-100" alt="Voluntariado Social CERMI" src="img/volunteering/volunteering_2.png" />
      </div>
    </div>
    <article class="m-top-2-rem">
      <p>Con este documento rector, el CERMI va más allá de los mandatos, orientaciones y recomendaciones de la Ley
        45/2015, de 14 de octubre, de Voluntariado, asumiéndolos en todo caso plenamente, confiriendo al voluntariado un
        papel de mayor protagonismo en la definición y despliegue de las estrategias de la acción representativa de la
        plataforma</p>
      <br />
      <p>El propósito, pues, de este documento es configurar unas líneas maestras que sirvan para orientar la
        participación
        ciudadana en las actividades del Movimiento CERMI a través del voluntariado social.</p>
      <br />
      <p>Tal como se recoge en sus Estatutos, el CERMI, como entidad genuinamente social, proclama y hace suyos los
        valores del altruismo, la acción solidaria y el voluntariado como parte integrante e irrenunciable de sus fines,
        de acuerdo con lo establecido en la Ley 45/2015, de 14 de octubre, de Voluntariado. El CERMI promoverá como seña
        de identidad de su cultura corporativa y de su acción representativa el voluntariado y la participación de
        las personas voluntarias en los procesos de toma de decisiones, en la definición de las estrategias y en el
        despliegue de las actividades sociales de la Entidad.</p>
      <br />
      <p>Este documento desarrolla asimismo las disposiciones normativas interna que regulan el voluntariado en el
        CERMI.</p>
    </article>
    <br />
    <div class="accordion box-content container">
      <div class="content container w-100">
        <ul class="list-item list">
          <li :aria-expanded="this.currentShow == 'aspace-1'">
            <input @click="toggle('aspace-1')" type="checkbox" name="list" id="aspace-1" />
            <label for="aspace-1">
              <span class="font-family-roboto text-primary">1.- Objetivos</span>
            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-1'">
              <p><strong>1.1.- Objetivo General</strong></p>
              <p>Establecer una política interna de voluntariado social inclusivo del Movimiento CERMI como parte
                integrante de la estrategia global de la entidad.</p>
              <p><strong>1.2.- Objetivos específicos</strong></p>
              <p>Objetivo 1.- Definir las líneas de participación solidaria y altruista de la ciudadanía en los procesos
                del movimiento CERMI.</p>
              <p>Objetivo 2.- Establecer el procedimiento interno aplicable al voluntariado social del movimiento CERMI.
              </p>

            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-2'">
            <input @click="toggle('aspace-2')" type="checkbox" name="list" id="aspace-2" />
            <label for="aspace-2">
              <span class="font-family-roboto text-primary">2.- Ámbitos de voluntariado social en el CERMI</span>
            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-2'">
              <p>La programación del voluntariado social en el movimiento CERMI se corresponde con las líneas estratégicas
                de la entidad y se diseña contando con los diferentes canales de participación ya existentes. Con ello, 
                se desea impulsar la participación de las personas voluntarias en los procesos de toma de
                decisiones y gobernanza.</p>
              <p>El CERMI pone a disposición de las personas voluntarias de un espacio digital para canalizar la
                participación ciudadana solidaria en la entidad.</p>
              <p><strong>LÍNEA 1.-Avanzar en la transformación social en clave de inclusión y defensa de derechos. El
                  CERMI como agente de cambio social.</strong></p>

              <p>- Voluntariado social para participar en la construcción de la agenda política de la discapacidad,
                voluntariado social en Derechos Humanos y contra la Discriminación y los Delitos de Odio, canalizados a
                través de las estructuras de
                apoyo del CERMI y del Comité de Apoyo.</p>
              <p>- Voluntariado social para participar en la orientación jurídica, canalizado a través de la Red de
                Defensa Legal de la Discapacidad.</p>

              <p><strong>LÍNEA 2.- Fortalecer la cultura de la innovación. CERMI como referente de innovación social en
                  discapacidad.</strong></p>

              <p>- Voluntariado social para participar en los procesos de gestión interna del CERMI: gestión económica;
                gestión de proyectos; gestión de personas.
              </p>
              <p><strong>LÍNEA 3.- Asentar la defensa del enfoque de género y la igualdad entre mujeres y hombres en
                  nuestra estrategia.</strong></p>
              <p>- Voluntariado social para participar en la construcción de la agenda política de las mujeres y niñas con
                discapacidad, canalizado a través del Consejo de Participación y del Programa de Voluntariado de la
                Fundación CERMI Mujeres.</p>
              <p><strong>LÍNEA 4.- Generar conocimiento e impacto social en materia de discapacidad.</strong></p>
              <p>- Voluntariado social para participar en la gestión de conocimiento, canalizado a través del Consejo de
                Ética del CERMI.</p>
              <p>- Voluntariado social para participar en la estrategia de comunicación.</p>
              <p><strong>LÍNEA 5.- Fortalecer el movimiento social de la discapacidad en España. El valor de la
                  unidad.</strong></p>
              <p>- Voluntariado social para participar en la construcción del movimiento CERMI.</p>
              <p>- Voluntariado corporativo del CERMI.</p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-3'">
            <input @click="toggle('aspace-3')" type="checkbox" name="list" id="aspace-3" />
            <label for="aspace-3">
              <span class="font-family-roboto text-primary">3.- Programación de voluntariado social</span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-3'">
              <p>El CERMI aprobará anualmente su Plan de Acción de Voluntariado Social, que se incluirá en el Plan de
                Actuación general de la Entidad, como capítulo propio, y que definirá con precisión la actividad que se
                desarrollará en ese periodo en esta materia.</p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-4'">
            <input @click="toggle('aspace-4')" type="checkbox" name="list" id="aspace-4" />
            <label for="aspace-4">
              <span class="font-family-roboto text-primary">4.- La persona voluntaria</span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-4'">
              <p>El movimiento CERMI quiere construir un voluntariado social inclusivo en el que pueda participar
                cualquier persona que desee canalizar su solidaridad y su deseo de transformación social en nuestra
                plataforma.</p>
              <p>Se pretende así impulsar especialmente el voluntariado activo de las personas con discapacidad, con un
                enfoque
                interseccional por género, edad y discapacidad, ya que es una vía más de participación social y
                empoderamiento de esta parte de la ciudadanía.</p>
              <p>Finalmente, queremos que se aspira a que el Equipo Humano participe en actividades de voluntariado
                social a través del correspondiente programa de voluntariado corporativo.</p>
              <p><strong>4.1.- Derechos de la persona voluntaria
                </strong></p>
              <p>Las personas que participen en el Movimiento CERMI en calidad de voluntarias tiene los siguientes
                derechos:
              </p>
              <p>1. Recibir regularmente durante la prestación de su actividad, información, orientación y apoyo, así como
                los medios materiales necesarios para el ejercicio de las funciones que se les encomienden.</p>


              <p>2. Recibir en todo momento, a cargo de la entidad de voluntariado, y adaptada a sus condiciones
                personales, la formación necesaria para el correcto desarrollo de las actividades que se les asignen.</p>


              <p>3. Ser tratadas en condiciones de igualdad, sin discriminación, respetando su libertad, identidad,
                dignidad y los demás derechos fundamentales reconocidos en los convenios, tratados internacionales y en la
                Constitución Española.</p>


              <p>4. Participar en el CERMI, colaborando en la elaboración, diseño, ejecución y evaluación de
                procesos de toma de decisiones, en la definición de las estrategias, en la gobernanza y en el despliegue
                de las actividades sociales del CERMI.</p>


              <p>5. Estar cubiertas, a cargo de la entidad de voluntariado, de los riesgos de accidente y enfermedad
                derivados directamente del ejercicio de la acción voluntaria y de responsabilidad civil, a través de un
                seguro.</p>


              <p>6. Ser reembolsadas por el CERMI de los gastos realizados en el desempeño de sus actividades, de acuerdo
                con lo previsto en el acuerdo de incorporación y teniendo en cuenta el ámbito de actuación de voluntariado
                que desarrollen.</p>

              <p>7. Disponer de una acreditación identificativa de su condición de persona voluntaria en la que conste que
                participan en el voluntariado social del CERMI.</p>


              <p>8. Realizar su actividad de acuerdo y con observancia el principio de accesibilidad universal adaptado a
                la actividad
                que desarrollen.</p>

              <p>9. Obtener reconocimiento por parte del CERMI, por el valor social de su contribución y por las
                competencias, aptitudes y destrezas adquiridas como consecuencia del ejercicio de su labor de
                voluntariado.</p>


              <p>10. Recibir el tratamiento y protección de datos personales adecuado conforme a lo establecido en la
                legislación de Protección de Datos de Carácter Personal.</p>

              <p>11. Cesar en la realización de sus actividades como persona voluntaria en los términos establecidos en el
                acuerdo de incorporación.</p>
              <p><strong>4.2.- Deberes de la persona voluntaria</strong></p>
              <p>Las personas que participen en el Movimiento CERMI en calidad de voluntarias tiene los siguientes
                deberes:
              </p>
              <p>1. Cumplir los compromisos adquiridos con el CERMI, reflejados en el acuerdo de incorporación, respetando
                lo establecido en los Estatutos Sociales, Reglamento Interno, Código Ético y Código de Bueno Gobierno.

              </p>


              <p>2. Guardar la debida confidencialidad de la información recibida y conocida en el desarrollo de su acción
                voluntaria.</p>


              <p>3. Rechazar cualquier contraprestación material o económica por razón de su acción voluntaria.</p>


              <p>4. Actuar con la diligencia debida y de forma solidaria.
              </p>

              <p>5. Participar en las tareas formativas previstas por el CERMI para las actividades y funciones confiadas,
                así como en las que con carácter permanente se precisen para mantener la calidad de los servicios que
                presten.</p>

              <p>6. Seguir las instrucciones del CERMI que tengan relación con el desarrollo de las actividades
                encomendadas.</p>


              <p>7. Utilizar debidamente la acreditación personal y los distintivos del CERMI.</p>


              <p>8. Respetar y cuidar los recursos materiales que ponga a su disposición el CERMI.</p>


              <p>9. Cumplir las medidas de seguridad y salud existentes en el CERMI.</p>

              <p>10. Observar las normas sobre protección y tratamiento de datos de carácter personal de acuerdo con la
                normativa en vigor.</p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-5'">
            <input @click="toggle('aspace-5')" type="checkbox" name="list" id="aspace-5" />
            <label for="aspace-5">
              <span class="font-family-roboto text-primary">5.- Participación de la persona voluntaria en el CERMI</span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-5'">
              <p><strong>5.1.- Acuerdo de incorporación</strong></p>

              <p>El CERMI firmará con cada persona voluntaria un acuerdo de incorporación, redactado con arreglo a lo
                establecido en la legislación vigente.</p>

              <p>El acuerdo se formalizará por escrito en duplicado y en formato digital accesible. Se acompañará de las
                certificaciones legales preceptivas cuando sea preciso (Registro Central de Penados o Declaración
                responsable).</p>
              <p><strong>5.2.- Registro</strong></p>

              <p>El CERMI habilitará un registro digital accesible donde se documenten los acuerdos de incorporación, toda
                la información relativa a las actividades de voluntariado realizadas y las altas y bajas de las personas
                voluntarias.</p>

              <p>Cualquier persona voluntaria del CERMI podrá acceder a dicha base de datos en todo momento para comprobar
                los datos propios referidos al seguimiento de su actividad voluntaria.</p>



              <p><strong>5.3.- Procedimiento</strong></p>

              <p>Las solicitudes de participación en los programas de voluntariado social del CERMI se canalizarán a
                través de cualquier canal de comunicación con el CERMI: espacio digital, sitio web corporativo
                www.cermi.es, sede electrónica, correo electrónico: voluntariado@cermi.es.</p>

              <p>La solicitud será valorada por la Gerencia del CERMI en el plazo de una semana desde su recepción, tras
                el que se formalizará el acuerdo de incorporación.</p>

              <p>Se establecerá un sistema de seguimiento de la participación, designándose la persona del Equipo Humano
                del CERMI y la Fundación CERMI Mujeres responsable del seguimiento de cada programa, atendiendo a la
                materia de este.</p>

              <p>El voluntariado corporativo se regirá por el contenido de la instrucción interna elaborada a tal efecto.
              </p>



              <p><strong>5.4.- Acogida</strong></p>

              <p>El CERMI establece un sistema definido de acogida de las personas voluntarias, a fin de facilitar su
                incorporación a la entidad.</p>



              <p><strong>5.5.- Formación</strong></p>

              <p>El CERMI se dota y establece un programa específico de formación continua para el voluntariado social, a
                través del cual las personas voluntarias reciban conocimientos sobre las siguientes materias:</p>
              <p><strong>Cuestiones generales</strong></p>
              <p>- Voluntariado social, concepto, derechos y deberes de las personas voluntarias.</p>
              <p>- Empoderamiento voluntario y activismo.</p>
              <p>- La discapacidad desde el enfoque de Derechos Humanos.</p>
              <p>- La discapacidad desde la perspectiva de Género y Derechos Humanos.</p>
              <p>- La Cultura CERMI: propósito, misión, visión, valores y objetivos estratégicos.</p>
              <p>Cuestiones específicas del programa en el que participan.</p>
              <p><strong>5.6.- Espacio CERMI de voluntariado social</strong></p>
              <p>El CERMI pondrá a disposición de las personas voluntarias un espacio digital inclusivo y accesible para
                canalizar cualquier vía de participación altruista y solidaria de la ciudadanía en nuestra entidad.</p>
              <p>Complementariamente, se creará en el sitio web corporativo <a href="http://www.cermi.es" target="_blank"
                  rel="noopener noreferrer">www.cermi.es</a> un espacio dedicado al voluntariado social con el siguiente
                contenido:</p>
              <p>Documento director y normas reguladoras de las actividades y funcionamiento interno del voluntariado en
                la Entidad CERMI.</p>
              <p>
                <a href="/pdf/Plan de Acción Voluntariado Social CERMI 2025.pdf" target="_blank"
                  rel="noopener noreferrer">Programación anual del voluntariado social del CERMI.</a>
              </p>
              <p><strong>5.7.- Red CERMI de voluntariado social</strong></p>
              <p>El CERMI dispone de una red de voluntariado social con todas las personas que participan de forma
                altruista y solidaria en nuestra entidad.
              </p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-6'">
            <input @click="toggle('aspace-6')" type="checkbox" name="list" id="aspace-6" />
            <label for="aspace-6">
              <span class="font-family-roboto text-primary">6.- Difusión del programa de voluntariado social</span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-6'">
              <p>El CERMI utilizará todos sus canales de comunicación para la captación, toma de conciencia y
                sensibilización de personas voluntarias.</p>

              <p><strong>6.1.- Presentación pública de la programación</strong></p>

              <p>Una vez aprobado el Plan de Actuación anual, en el que figure el plan específico de voluntariado social
                del CERMI para ese periodo, se presentarán y difundirán por los canales públicos del CERMI los contenidos
                del mismo, notificándolo a la red de personas voluntarias de la entidad.</p>

              <p><strong>6.2.- Difusión pública de resultados</strong></p>

              <p>Con carácter anual, al cierre del año, coincidiendo con el Día Internacional de las Personas Voluntarias,
                5 de diciembre, el CERMI presentará y difundirá un informe balance con los resultados de su Programa de
                Voluntariado Social, que se incluirán
                asimismo en la Memoria anual de Actividades y en la Memoria anual de RSE de la entidad.</p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-7'">
            <input @click="toggle('aspace-7')" type="checkbox" name="list" id="aspace-7" />
            <label for="aspace-7">
              <span class="font-family-roboto text-primary">7.- Alianza con la Plataforma de Voluntariado de España,
                PVE</span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-7'">
              <p>El CERMI mantendrá de forma permanente un Marco de Colaboración con la Plataforma del Voluntariado de
                España, PVE,
                para promover la cooperación entre ambas entidades sobre voluntariado social.</p>
              <p>La persona titular de la Gerencia del CERMI asumirá la función de enlace e interacción que se derive de
                esta Alianza CERMI/PVE.
              </p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-8'">
            <input @click="toggle('aspace-8')" type="checkbox" name="list" id="aspace-8" />
            <label for="aspace-8">
              <span class="font-family-roboto text-primary">8. Transferencia territorial </span>

            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-8'">
              <p>El CERMI Estatal traslada toda su cultura, decisiones y acciones de voluntariado a la red de CERMIS
                Autonómicos (19 plataformas territoriales) para reforzar así la dimensión voluntaria en toda el Movimiento
                CERMI, tanto a escala nacional como autonómica.</p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-9'">
            <input @click="toggle('aspace-9')" type="checkbox" name="list" id="aspace-9" />
            <label for="aspace-9">
              <span class="font-family-roboto text-primary">9.- Responsable de la gestión </span>
            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-9'">
              <p>
                Se designa a la persona titular de la Gerencia del CERMI como la responsable del impulso, gestión,
                despliegue, seguimiento y reporte de lo contenido en este documento director, dando cuenta de lo principal
                de lo actuado a la Presidencia, a la Secretaría General y a la Dirección Ejecutiva de la entidad.
              </p>
            </div>
          </li>
          <li :aria-expanded="this.currentShow == 'aspace-10'">
            <input @click="toggle('aspace-10')" type="checkbox" name="list" id="aspace-10" />
            <label for="aspace-10">
              <span class="font-family-roboto text-primary">10.- Vigencia</span>
            </label>
            <div class="inner-container p-left-0-px p-1-rem " :show="this.currentShow == 'aspace-10'">
              <p>
                Lo contenido en este documento director tendrá vigencia indefinida, comenzando sus efectos desde su
                aprobación formal por el Comité Ejecutivo del CERMI el 15 de septiembre de 2022. Se faculta en todo caso a
                la Presidencia y a la Secretaría General de la entidad a modificarlo parcialmente para actualizarlo a
                nuevas circunstancias que sea relevantes para mejorar la acción voluntaria de la plataforma.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="m-bottom-2-rem">
      <other-links-interest class="links-interest-box container" />
    </div>
  </section>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";

export default {
  name: "Volunteering",
  components: { OtherLinksInterest, HeroGlobal },
  data: () => ({
    currentShow: null
  }),
  methods: {
    toggle(id) {
      if (this.currentShow === id) {
        this.currentShow = null;
      } else {
        this.currentShow = id;
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "../styles/colours";

.box-content {
  background: transparent !important;

  .list-item {
    list-style: none;
    padding: 15px 0;

    label {
      padding: 0;
      font-size: 22px;

      &:before {
        bottom: -10px !important;
      }

      span {
        font-size: 20px;
        padding-left: 0 !important;
        color: black;
      }

    }
  }
}

.logo-voluntariado {
  margin-bottom: 30px;
  max-width: 800px;
}
@media screen and (max-width: 895px) { 
  .logo-voluntariado {
    max-width: 400px;
  }
}

@media screen and (max-width: 495px) { 
  .logo-voluntariado {
    max-width: 300px;
  }
}
</style>